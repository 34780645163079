module.exports = {
  getColor: (Color) => {
    let theColor = String(Color).toLowerCase();
    if (theColor.includes('black')) {
      return 'Black'
    }  else if (theColor.includes('light blue')) {
      return 'Light Blue'
    }  else if (theColor.includes('dark blue')) {
      return 'Dark Blue'
    } else if (theColor.includes('gray')) {
      return 'Gray'
    } else if (theColor.includes('red')) {
      return 'Red'
    } else if (theColor.includes('silver')) {
      return 'Silver'
    } else if (theColor.includes('blue')) {
      return 'Blue'
    } else if (theColor.includes('green')) {
      return 'Green'
    }  else if (theColor.includes('white')) {
      return 'White'
    }  else if (theColor.includes('yellow')) {
      return 'Yellow'
    }  else if (theColor.includes('orange')) {
      return 'Orange'
    }  else if (theColor.includes('brown')) {
      return 'Brown'
    }else {
      return 'Other'
    }
  },
  getMileage: (mileage,range) => {
    range = (range != null ? range : 10000)
    return Math.ceil(parseInt(mileage) / range) * range
  },
  getCost: (cost,range) => {
    range = (range != null ? range : 5000)
    return Math.ceil(parseInt(cost) / range) * range
  },
  getTransmissionType: (Transmission) => {
    if (Transmission.includes('Automatic')) {
      return 'Automatic'
    } else if (Transmission.includes('Manual')) {
      return 'Manual'
    } else {
      return Transmission
    }
  },
  getFuelType: (Fuel) => {
    if(Fuel.includes('Gas') && Fuel.includes('Electric')){
      return 'Hybrid'
    }else if (Fuel.includes('Electric')){
      return 'Electric'
    }else if(Fuel.includes('Diesel')){
      return 'Diesel'
    }else if (Fuel.includes('Gas') || Fuel.includes('Unleaded') || Fuel.includes('gasoline')){
      return 'Gas'
    }else {
      return 'Other'
    }

  }
}

